import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_ssafe.svg'
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
<GatsbySeo title='PanSurg SSAFE Results'
      description='Staff and SAfety eFfects of Epidemics'/>
  <Hero title="PanSurg SSAFE"
        subtitle="Staff and SAfety eFfects of Epidemics"
        fluid={data.hero.childImageSharp.fluid}
        buttonTxt = "Participate"
        buttonLink= "https://docs.google.com/forms/d/e/1FAIpQLSfMb1f7M9ZG_MMbCFo-nII-pRTXRJfFKroaEa4hz0lX7iBjrQ/viewform?fbzx=6071366802869538435"
        breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        thirdcrumb = 'SSAFE'
        thirdlink = '/ssafe'
        activecrumb = 'SSAFE Data'
  />

  <section className="section is-small" style={{marginTop:'2rem'}}>
    <div className="container">

        <div className="has-text-centered">
          <img src={logo} width="400px" alt="SSAFE Logo" style={{marginBottom:'2rem'}}/>
        </div>

        <hr />
        <div className="box">
        <iframe src="https://pansurg.shinyapps.io/ssafe_study/" width="100%" height="2000px"></iframe>
        </div>
    </div>
  </section>

  </>
  )
}
